import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export default function AuthGuard({children}) {
    
    const { isLoggedIn } = useSelector((state) => state.auth);
    const token = localStorage.getItem('token');

    // If the user is not logged in and there is no token in localStorage, redirect to login
    if (!isLoggedIn && !token) {
        return <Navigate to="/login" />;
    }

    // If logged in, allow access to the protected routes
    return <Outlet/>
};

import React, { useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../redux/slice/authSlice';
import { NavLink } from 'react-router-dom';

export default function Dashboard() {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(login());
    },[dispatch]);


    return (
        <React.Fragment>
            <Col lg="9">
                <div className="my-account-content account-dashboard">
                    <div className="mb_60">
                        <h5 className="fw-5 mb_20">Hello {user ? user.name : "User"}</h5>
                        <p>
                            From your account dashboard you can view your 
                            <NavLink className="text_primary" to="/account/my-account-orders">recent orders</NavLink> , 
                            manage your 
                            <NavLink className="text_primary" to="/account/my-account-address">shipping and billing address</NavLink> ,
                            and 
                            <NavLink className="text_primary" to="/account/my-account-edit">edit your password and account details</NavLink> .
                        </p>
                    </div>
                </div>
            </Col>
        </React.Fragment>
    )
}

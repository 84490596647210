import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../components/global/AxiosSetting';


export const updatePassword = createAsyncThunk(
    'user/updatePassword',
    async (passwordData, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(`/change-password`, passwordData);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

const updatePasswordSlice = createSlice({
    name: "updatePassword",
    initialState: {
        loading: false,
        success: null,
        error: null,
    },
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.success = null;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updatePassword.pending, (state) => {
                state.loading = true;
                state.success = null;
                state.error = null;
            })
            .addCase(updatePassword.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload.message;
                state.error = null;
            })
            .addCase(updatePassword.rejected, (state, action) => {
                state.loading = false;
                state.success = null;
                state.error = action.payload; // Updated here to handle error properly
            });
    },
});

export const { resetState } = updatePasswordSlice.actions;
export default updatePasswordSlice.reducer;

import axios from 'axios';
import { API_URL } from './GlobalVariable';

// import { clearAuth } from '../../redux/slice/authSlice';
// import { store } from '../../redux/store/store'

let authToken = "";

export function updateToken(token) {
  console.log({authToken, token})
  authToken = token;
}

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
  },
});

axiosInstance.interceptors.request.use(function (config) {
  if (authToken) {
    config.headers['Authorization'] = `Bearer ${authToken}`
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  console.log("RESPONSE_ERR: ", error);
  if (error.status === 401) {
    (async function() {
      const { clearAuth } = await import('../../redux/slice/authSlice');
      const { store } = await import('../../redux/store/store');
      store.dispatch(clearAuth());
    })()
  }

  return Promise.reject(error);
});

export default axiosInstance;

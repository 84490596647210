import React, { useEffect, useState } from 'react';
import BreadCrumb from '../elements/product/BreadCrumb';
import { Container } from 'react-bootstrap';
import { NavLink, useSearchParams } from 'react-router-dom';
import { BASE_URL } from '../global/GlobalVariable';
import { toggleWishlist } from '../global/server-api';
import { useDispatch, useSelector } from 'react-redux';
import { updateWishlist } from '../../redux/slice/productSlice';

const Product = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const category = searchParams.get('category');

    useEffect(() => {
        toggleSelectedCategory(+category);
    }, [category]);

    const dispatch = useDispatch();
    const { products } = useSelector((state) => state.products);
    const { categories } = useSelector((state) => state.categories); 
    const { isLoggedIn } = useSelector((state) => state.auth);

    const [productData, setProductData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(undefined);

    useEffect(() => {
        if (products?.data?.length) {
            let product_data = [];
            if (selectedCategory) product_data = products.data.filter(x => x.category_id == selectedCategory);
            else product_data = products.data;

            setProductData(product_data);
        }
    }, [products, selectedCategory]);

    const toggleSelectedCategory = category_id => {
        if (selectedCategory == category_id) setSelectedCategory(undefined);
        else setSelectedCategory(category_id);
    };

    const onToggleWishlist = async product_id => {
        try {
            const response = await toggleWishlist(product_id);
            if (response.data) {
                console.log(response.data);
                const {isWishlist} = response.data;
                dispatch(updateWishlist({productId: product_id, isWishlist}))
            }
        } catch (error) {
            console.log({error});
            console.log(error.response);
        }
    };
    
    return (
        <React.Fragment>
            <BreadCrumb title="Products"/>

            <section className='flat-spacing-2'>
                <Container>
                    <div className="tf-shop-control grid-3 align-items-center">
                        <div className="tf-control-filter">
                            {/* <NavLink to="#filterShop" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft" className="tf-btn-filter"><span className="icon icon-filter"></span><span className="text">Filter</span></NavLink> */}
                        </div>
                        <ul className="tf-control-layout d-flex justify-content-center">
                           
                            
                        </ul>
                        {/* <div className="tf-control-sorting d-flex justify-content-end">
                            <div className="tf-dropdown-sort" data-bs-toggle="dropdown">
                                <div className="btn-select">
                                    <span className="text-sort-value">Featured</span>
                                    <span className="icon icon-arrow-down"></span>
                                </div>
                                <div className="dropdown-menu">
                                    <div className="select-item active">
                                        <span className="text-value-item">Featured</span>
                                    </div>
                                    <div className="select-item">
                                        <span className="text-value-item">Best selling</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div> */}
                    </div>
                    <div className="tf-row-flex">
                        <aside className="tf-shop-sidebar wrap-sidebar-mobile">
                            <div className="widget-facet wd-categories">
                                <div className="facet-title" data-bs-target="#categories" data-bs-toggle="collapse" aria-expanded="true" aria-controls="categories">
                                    <span>Product categories</span>
                                    <span className="icon icon-arrow-up"></span>
                                </div>
                                <div id="categories" className="collapse show">
                                    <ul className="list-categoris current-scrollbar mb_36">
                                    {
                                        categories?.data?.length > 0 ? (
                                            categories.data.map(cat => (
                                                <li onClick={() => toggleSelectedCategory(cat.id)} className={cat.id == selectedCategory ? "cate-item selected-cat" : "cate-item"}>
                                                    <p><span>{cat.name}</span>&nbsp;{/*<span>({cat.product.length})</span>*/}</p>
                                                </li>
                                            ))
                                        ) : (
                                            <p>Loading...</p>
                                        )
                                    }
                                    </ul>
                                </div>

                            </div>
                        
                            <div className="widget-facet">
                                <div className="facet-title" data-bs-target="#gallery" data-bs-toggle="collapse" aria-expanded="true" aria-controls="gallery">
                                    <span>Gallery</span>
                                    <span className="icon icon-arrow-up"></span>
                                </div>
                                <div id="gallery" className="collapse show">
                                    <div className="grid-3 gap-4 mb_36">
                                    {
                                        productData.length > 0 ? (
                                            productData.map(data => (
                                            <NavLink key={`image-${data.slug}`} to={`/product-detail/${data.slug}`} className="item-gallery" style={{height:"100px"}}>
                                                <img className="lazyload" data-src={`${BASE_URL}/${data.images[0]}`} src={`${BASE_URL}/${data.images[0]}`} alt="img-gallery" style={{height:"100%", objectFit: 'contain'}}/>
                                            </NavLink>
                                            ))
                                        ) : (
                                            <p>Loading...</p>
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className="widget-facet">
                                <div className="facet-title" data-bs-target="#follow" data-bs-toggle="collapse" aria-expanded="true" aria-controls="follow">
                                    <span>Follow us</span>
                                    <span className="icon icon-arrow-up"></span>
                                </div>
                                <div id="follow" className="collapse show">
                                    <ul className="tf-social-icon d-flex gap-10">
                                        <li><NavLink to="#" className="box-icon w_34 round bg_line social-facebook"><i className="icon fs-14 icon-fb"></i></NavLink></li>
                                        <li><NavLink to="#" className="box-icon w_34 round bg_line social-twiter"><i className="icon fs-12 icon-Icon-x"></i></NavLink></li>
                                        <li><NavLink to="#" className="box-icon w_34 round bg_line social-instagram"><i className="icon fs-14 icon-instagram"></i></NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </aside>
                    <div className="tf-shop-content">
                        <div className="grid-layout wrapper-shop" data-grid="grid-3">
                            {
                                productData.length > 0 ? (
                                productData.map((data, index) => (
                                <div key={`product-${index}`} className="card-product radius-10 style-giftcard">
                                    <div className="card-product-wrapper">
                                        <NavLink to={`/product-detail/${data.slug}`} className="product-img w-100">
                                            <img 
                                                className="lazyload img-product" 
                                                data-src={`${BASE_URL}/${data.images[0]}`} 
                                                src={`${BASE_URL}/${data.images[0]}`} 
                                                alt="image-product" 
                                                style={{height:"289px"}}
                                            />
                                            <img 
                                                class="lazyload img-hover" 
                                                data-src={`${BASE_URL}/${data.images[1]}`} 
                                                src={`${BASE_URL}/${data.images[1]}`} 
                                                alt="image-product"
                                                style={{height:"289px"}}
                                            />
                                            </NavLink>
                                            <div className="list-product-btn">
                                                {isLoggedIn ? 
                                                    <NavLink 
                                                        to={'#'} 
                                                        onClick={() => onToggleWishlist(data.id)} 
                                                        className={data.wishtlist?.length ? "box-icon bg_white wishlist btn-icon-action mr-active" : "box-icon bg_white wishlist btn-icon-action"}>
                                                        <span className="tooltip">{data.wishtlist?.length ? 'Remove From' : 'Add To'} Wishlist</span>
                                                        <span className="icon icon-heart"></span>
                                                    </NavLink>
                                                : 
                                                    <NavLink to={'/login'} className="box-icon bg_white wishlist btn-icon-action">
                                                        <span className="tooltip">Add to Wishlist</span>
                                                        <span className="icon icon-heart"></span>
                                                    </NavLink>
                                                }
                                            </div>
                                        </div>
                                        <div className="card-product-info">
                                            <NavLink to={`/product-detail/${data.slug}`} className="title link fw-6 text-capitalize">{data.name.slice(0,30)}</NavLink>
                                            <span className="price text-secondary fw-5"><span>{data.short_description.slice(0,45)}...</span></span>
                                            <div className="tf-product-btns">
                                                <NavLink to={`/product-detail/${data.slug}`} className="fw-7 fs-12 w-100 d-flex justify-content-center text-capitalize">View Detail</NavLink>
                                            </div>
                                    </div>
                                </div>
                               ))
                                ) : (
                                    <p>No products available.</p> // Handle case where there are no products
                                )
                            }
                            
                        </div>  
                        {/* Pagination */}
                        {/* <ul className="tf-pagination-wrap tf-pagination-list mt-5">
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <li key={index} className={currentPage === index + 1 ? 'active' : ''}>
                                        <NavLink
                                            to="#"
                                            className="pagination-link"
                                            onClick={() => handlePageChange(index + 1)}
                                        >
                                            {index + 1}
                                        </NavLink>
                                    </li>
                                ))}
                        </ul> */}
                    </div>
                </div>
                    
                </Container>
            </section>
        </React.Fragment>
    );
}

export default Product;

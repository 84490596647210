import React, {useEffect, useState} from 'react';
import InnerBanner from '../elements/InnerBanner';
import { useNavigate } from 'react-router-dom';
import { currency } from '../../utils/currency';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getAddress, postAddress, postOrder } from '../global/server-api';
import { withSwal } from 'react-sweetalert2';
import { BASE_URL } from '../global/GlobalVariable';
import { clearCart } from '../../redux/slice/cartSlice';

const Checkout = ({swal}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {data: cartData} = useSelector(state => state.cart);

    const addressInitData = {
        name: '',
        mobile: '',
        alternate_mobile: '',
        address: '',
        near_by: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        default: 1
    };

    const { register, handleSubmit, setValue, reset, formState: { errors }} = useForm({
        defaultValues: addressInitData
    });

    const resetForm = () => {
        reset(addressInitData);
    };

    const [sub_total, setSubTotal] = useState(0);
    const [userAddress, setUserAddress] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [orderLoading, setOrderLoading] = useState(false);

    useEffect(() => {
        let total = 0;
        for (let x of cartData) {
            total += (x.qty * x.admin_price);
        }
        setSubTotal(total);
    }, [cartData]);

    const getUserAddress = async () => {
        if (loading) return;
        setLoading(true);

        try {
            const response = await getAddress();
            if (response.data && response.data.data?.length) {
                setUserAddress(response.data.data[0]);
                resetForm();
            }
            setLoading(false)
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUserAddress();
    }, []);

    const onAnotherAddress = () => {
        setUserAddress(undefined);
    };

    const onAddressSubmit = async data => {
        console.log({data});
        if (loading) return;
        setLoading(true);

        try {
            const response = await postAddress(data)
            if (response.data) {
                console.log(response.data);
                if (response.data.data) setUserAddress(response.data.data);
                successMsg(response.data.successMsg);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error?.response?.data) {
                errorMsg(error.response.data.message);
            }
        }
    };

    const successMsg = (message, order_success = false) => {
        swal.fire({
            title: 'Success!',
            text: message || 'Price request sent successfully!',
            icon: 'success',
            didClose: () => {
                console.log("CLOSED SWEETALERT!")
                if (order_success) {
                    handleSuccessPlaceOrder();
                }
            }
        });
    }

    const errorMsg = message => {
        swal.fire({
            title: 'Error!',
            text: message || 'Failed to send request.',
            icon: 'error',
        });
    };

    const handleSuccessPlaceOrder = () => {
        dispatch(clearCart());
        navigate('/account/my-account-orders');
    };

    const onPlaceOrder = async () => {
        if (!userAddress) {
            errorMsg("Please Add An Address First!");
            return;
        }

        if (orderLoading) return;

        let items = [];

        for (const cart of cartData) {
            items.push({
                product_id: cart.product_id,
                product_price_id: cart.id,
                qty: cart.qty,
                price: cart.admin_price,
                total_price: (cart.qty * cart.admin_price)
            });
        }

        let send_data = {
            address_id: userAddress.id,
            sub_total,
            discount: 0,
            all_total: sub_total,
            items
        };

        console.log({send_data});

        setOrderLoading(true);

        try {
            const response = await postOrder(send_data);
            if (response.data) {
                successMsg(response.data.successMsg, true);
            }
            setOrderLoading(false);
        } catch (error) {
            setOrderLoading(false);
            if (error?.response?.data) {
                errorMsg(error.response.data.message);
            }
        }
    };

    if (!cartData.length) return (
        <React.Fragment>
            <InnerBanner title="Checkout"/>

            <section className="flat-spacing-11">
                <div className="container">
                    <div className="tf-page-cart-wrap layout-2">
                        <div className="tf-page-cart-item">
                            <h5 className="fw-5 mb_20">Please Add Product in the Bag First.</h5>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
  
    return (
        <React.Fragment>
            <InnerBanner title="Checkout"/>

            <section className="flat-spacing-11">
                <div className="container">
                    <div className="tf-page-cart-wrap layout-2">
                        <div className="tf-page-cart-item">
                            <h5 className="fw-5 mb_20">Billing details</h5>
                            <form onSubmit={handleSubmit(onAddressSubmit)} className="form-checkout">
                                {userAddress ? 
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <h5>{userAddress.name}</h5>
                                            <h6>{userAddress.mobile}{userAddress.alternate_mobile ? `, ${userAddress.alternate_mobile}` : ''}</h6>
                                            <p>{userAddress.address}, {userAddress.city}, {userAddress.state}, {userAddress.country}, {userAddress.pincode}</p>
                                            {userAddress.near_by ? <p>{userAddress.near_by}</p> : null}
                                        </div>
                                        <div className="col-12">
                                            <button type="button" onClick={onAnotherAddress} className='tf-btn radius-3 btn-fill btn-icon animate-hover-btn'>Not This Address?</button>
                                        </div>
                                    </div>
                                : 
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <fieldset className="box fieldset">
                                                <label for="full-name">Full Name</label>
                                                <input type="text" id="full-name" name='name' {...register('name', { required: true })}/>
                                            </fieldset>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <fieldset className="box fieldset">
                                                <label for="mobile">Mobile</label>
                                                <input type="text" id="mobile" name='mobile' placeholder="9876...." {...register('mobile', { required: true })}/>
                                            </fieldset>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <fieldset className="box fieldset">
                                                <label for="alternate_mobile">Alternate Mobile <small>(Optional)</small></label>
                                                <input type="text" id="alternate_mobile" name='alternate_mobile' {...register('alternate_mobile')}/>
                                            </fieldset>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <fieldset className="box fieldset">
                                                <label for="address">Address</label>
                                                <input type="text" id="address" name="address" {...register('address')}/>
                                            </fieldset>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <fieldset className="box fieldset">
                                                <label for="near_by">Near By <small>(Optional)</small></label>
                                                <input type="text" id="near_by" name="near_by" {...register('near_by')}/>
                                            </fieldset>
                                        </div>
                                        <div className="col-6 mb-3">
                                            <fieldset className="box fieldset">
                                                <label for="city">Town/City</label>
                                                <input type="text" id="city" name="city" {...register('city')}/>
                                            </fieldset>
                                        </div>
                                        <div className="col-6 mb-3">
                                            <fieldset className="box fieldset">
                                                <label for="state">State</label>
                                                <input type="text" id="state" name="state" {...register('state')}/>
                                            </fieldset>
                                        </div>

                                        <div className="col-6 mb-3">
                                            <fieldset className="box fieldset">
                                                <label for="country">Country</label>
                                                <input type="text" id="country" name="country" {...register('country')}/>
                                            </fieldset>
                                        </div>
                                        <div className="col-6 mb-5">
                                            <fieldset className="box fieldset">
                                                <label for="pincode">Pincode</label>
                                                <input type="text" id="pincode" {...register('pincode')}/>
                                            </fieldset>
                                        </div>

                                        <div className="col-12">
                                            <button type="submit" className="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center">
                                                {loading ? 'Please wait ...' : 'Add Address'}
                                            </button>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                        <div className="tf-page-cart-footer">
                            <div className="tf-cart-footer-inner">
                                <h5 className="fw-5 mb_20">Your order</h5>
                                <form className="tf-page-cart-checkout widget-wrap-checkout">
                                    <ul className="wrap-checkout-product">
                                        {cartData.map(x => <li key={`c-${x.id}`} className="checkout-product-item">
                                            <figure className="img-product">
                                                <img src={`${BASE_URL}/${x.product.images[0]}`} alt="product"/>
                                                <span className="quantity">{x.qty}</span>
                                            </figure>
                                            <div className="content">
                                                <div className="info">
                                                    <p className="name">{x.product.name}</p>
                                                </div>
                                                <span className="price">{currency.icon}{x.admin_price}</span>
                                            </div>
                                        </li>)}
                                    </ul>
                                    <div className="d-flex justify-content-between line pb_20">
                                        <h6 className="fw-5">Total</h6>
                                        <h6 className="total fw-5">{currency.icon}{sub_total}</h6>
                                    </div>
                                    <button type='button' onClick={onPlaceOrder} 
                                        className={`tf-btn radius-3 btn-fill btn-icon ${userAddress ? 'animate-hover-btn' : ''} justify-content-center`}>
                                        {orderLoading ? 'Please wait ...' : 'Place order'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
  )
}

export default withSwal(Checkout);

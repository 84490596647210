import React from 'react';

const Preloader = () => {
    
    return (
        <div className='preload-wrapper'>
            {/* preload */}
            <div className="preload preload-container">
                <div className="preload-logo">
                    <div className="spinner"></div>
                </div>
            </div>
            {/* /preload */}
        </div>
    );
}

export default Preloader;

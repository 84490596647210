import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../../redux/slice/categorySlice';
import { fetchUserData } from '../../../redux/slice/authSlice';
import { fetchWebsiteSettings } from '../../../redux/slice/websiteSlice';

const MobileMenu = () => {

    const dispatch = useDispatch();
    const { categories, loading, error } = useSelector((state) => state.categories); // Get categories from state
    const { isLoggedIn, user } = useSelector((state) => state.auth); // Assuming auth slice
    const { data } = useSelector((state) => state.website || {  });

    // useEffect(() => {
    //     dispatch(fetchCategories()); // Fetch categories when component mounts
    //     dispatch(fetchWebsiteSettings());
    //     if (isLoggedIn && !user) dispatch(fetchUserData());   // Fetch user data if necessary
    // }, []);

    // if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="offcanvas offcanvas-start canvas-mb" id="mobileMenu">
            <span className="icon-close icon-close-popup" data-bs-dismiss="offcanvas" aria-label="Close"></span>
            <div className="mb-canvas-content">
                <div className="mb-body">
                    <ul className="nav-ul-mb" id="wrapper-menu-navigation">
                        <li className="nav-mb-item">
                            <NavLink to="/" className="collapsed mb-menu-link current">
                                <span>Home</span>
                            </NavLink>
                        </li>
                        <li className="nav-mb-item">
                            <NavLink to="/about" className="collapsed mb-menu-link current">
                                <span>About us</span>
                            </NavLink>
                        </li>
                        <li className="nav-mb-item">
                            <NavLink to="/product" data-bs-target="#dropdown-menu-two" className="collapsed mb-menu-link current" data-bs-toggle="collapse" aria-expanded="true" aria-controls="dropdown-menu-two">
                                <span>Products</span>
                                <span className="btn-open-sub"></span>
                            </NavLink>
                            <div id="dropdown-menu-two" className="collapse">
                                <ul className="sub-nav-menu" id="sub-menu-navigation">
                                {
                                    loading ? <p>Loading...</p> :
                                    categories?.data?.map(cat => (
                                        <li key={`cat-${cat.id}`}>
                                            <NavLink to="#" className="sub-nav-link collapsed" data-bs-target="#sub-shop-one" data-bs-toggle="collapse" aria-expanded="true" aria-controls="sub-shop-one">
                                                <span>{cat.name}</span>
                                                <span className="btn-open-sub"></span>
                                            </NavLink>
                                            <div id="sub-shop-one" className="collapse">
                                                <ul className="sub-nav-menu sub-menu-level-2">
                                                {
                                                    cat.product?.map(data => (
                                                        <li><NavLink to={`/product-detail/${data.slug}`} className="sub-nav-link">{data.name}</NavLink></li>
                                                    ))
                                                }    
                                                </ul>
                                            </div>
                                        </li>
                                    ))
                                }
                                </ul>
                            </div>
                        </li>
                        <li className="nav-mb-item">
                            <NavLink to="/blog" className="collapsed mb-menu-link current">
                                <span>Blog</span>
                            </NavLink>
                        </li>
                        <li className="nav-mb-item">
                            <NavLink to="/contact" className="collapsed mb-menu-link current">
                                <span>Contact us</span>
                            </NavLink>
                        </li>
                    </ul>
                    <div className="mb-other-content">
                        <div className="d-flex group-icon">
                            <NavLink to="/wishlist" className="site-nav-icon"><i className="icon icon-heart"></i>Wishlist</NavLink>
                            <NavLink to="/home-search" className="site-nav-icon"><i className="icon icon-search"></i>Search</NavLink>
                        </div>
                        <div className="mb-notice">
                            <NavLink to="/contact" className="text-need">Need help ?</NavLink>
                        </div>
                        <ul className="mb-info">
                            <li>{data.address}</li>
                            <li>Email: <b>{data.email}</b></li>
                            <li>Phone: <b>{data.phone}</b></li>
                        </ul>
                    </div>
                </div>
                <div className="mb-bottom">
                    <NavLink to={isLoggedIn ? "/account" : "/login"} className="site-nav-icon"><i className="icon icon-account"></i>{isLoggedIn ? "Account" : "Login"}</NavLink>
                  
                </div>
            </div>       
        </div>
    );
}

export default MobileMenu;

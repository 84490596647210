// features/productsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../components/global/AxiosSetting';

export const fetchSingleProduct = createAsyncThunk(
    'products/fetchSingleProduct',
    async ({slug, isLoggedIn}) => {
        let productUrl = '/products';
        if (isLoggedIn) productUrl = `/user${productUrl}`

        const response = await axiosInstance.get(`${productUrl}/${slug}`);
        console.log("SingelSlice__:",response.data);
        return response.data;
    }
);

const SingleProductSlice = createSlice({
    name: 'product',
    initialState: {
        product: {data: {}},
        status: 'idle', // idle | loading | succeeded | failed
        loading: false,
        error: null,
    },
    reducers: {
        updateSingleWishlist(state, action) {
            const {productId, isWishlist} = action.payload;
            let productData = [...state.product.data];
            if (productId == productData.id) {
                if (isWishlist) productData.wishtlist = [{user_id: 12, product_id: productId}];
                else productData.wishtlist = [];
                state.product.data = productData;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSingleProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSingleProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.product = action.payload; // Save product details to the state
            })
            .addCase(fetchSingleProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { updateSingleWishlist } = SingleProductSlice.actions;
export default SingleProductSlice.reducer;

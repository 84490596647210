import React from 'react'
import InnerBanner from '../elements/InnerBanner'

export default function Wishlist() {

  return (
    <React.Fragment>
        <InnerBanner title="Wishlist"/>
        
    </React.Fragment>
  )
}

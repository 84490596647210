import axiosInstance from "./AxiosSetting";


export const toggleWishlist = product_id => axiosInstance.get(`/user/wishlists/${product_id}`);

export const wishlistProducts = () => axiosInstance.get('/user/wishlists');

export const getProductPriceList = () => axiosInstance.get('/user/product-prices');

export const postProductPrice = data => axiosInstance.post('/user/product-prices', data);

export const deleteProductPrice = product_price_id => axiosInstance.post(`/user/product-prices/${product_price_id}`, {});

export const getAddress = () => axiosInstance.get('/user/address');

export const postAddress = data => axiosInstance.post('/user/address', data);

export const getOrder = () => axiosInstance.get('/user/orders');

export const postOrder = data => axiosInstance.post('/user/orders', data);

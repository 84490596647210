import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../components/global/GlobalVariable';
import axiosInstance from '../../components/global/AxiosSetting';


// Define the initial state for home data
const initialState = {
    blogs: [],  // Store blogs
    singleBlog: {}, // Store a single blog
    dataLoaded: false,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    loading: false,
    error: null,
};

// Create async thunk for fetching home data
export const fetchBlogData = createAsyncThunk('blog/fetchBlogData', async () => {
    const response = await axiosInstance.get("/blogs");
    return response.data;
});

// Create async thunk for fetching a single blog by slug
export const fetchSingleBlog = createAsyncThunk('singleBlog/fetchSingleBlog', async (slug) => {
    const response = await axiosInstance.get(`/blogs/${slug}`);
    console.log("SingleBlog__", response.data);
    return response.data; // Return the single blog data
});


// Create the slice
const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlogData.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(fetchBlogData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.loading = false;
                console.log("Blog Data Response:", action.payload);  // Debugging
                state.blogs = action.payload.data;  // Assuming action.payload is an array
                state.dataLoaded = true;
            })
            .addCase(fetchBlogData.rejected, (state, action) => {
                state.status = 'failed';
                state.loading = false;
                state.error = action.error.message;
            })
            
            // Handling fetch single blog by slug
            .addCase(fetchSingleBlog.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(fetchSingleBlog.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.loading = false;
                console.log("Single Blog Response:", action.payload);  // Debugging
                state.singleBlog = action.payload.data;  // Assuming action.payload is the blog object
            })
            .addCase(fetchSingleBlog.rejected, (state, action) => {
                state.status = 'failed';
                state.loading = false;
                state.error = action.error.message;
            });

    },
});

// Export the async thunk and the reducer
export default blogSlice.reducer;
import React from "react";
import { Container } from "react-bootstrap";
import '../style/error-page.css';


const ErrorPage = () => {
   
    return (
        <Container>
            <img src="assets/images/item/error-page.jpg" alt="error" />
        </Container>
    );
};

export default ErrorPage;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { BASE_URL } from '../../global/GlobalVariable';
import { currency } from '../../../utils/currency';
import { formatDate } from '../../../utils/dateFormate';
import Preloader from '../../include/Preloader';

export default function RequestDetail({ reqID }) {
    const [requestData, setRequestData] = useState(null);
    const { data, loading } = useSelector((state) => state.priceRequest);


    useEffect(() => {
        if (data?.data?.length) { // Ensure data structure is correct
            const findRequest = data.data.find((item) => item.id === +reqID);
            setRequestData(findRequest);
        }
    }, [data, reqID]);

    if (loading) {
        return <Preloader />;
    }

    if (!requestData) {
        return <p>No request data found for this ID.</p>;
    }

    return (
        <React.Fragment>
        <div className="modal modalCentered fade modalDemo tf-product-modal modal-part-content" id="requestModal">
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="header">
                <div className="demo-title">Your Request</div>
                <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                </div>
                <div className="wd-form-order">
                <div className="order-head">
                    <figure className="img-product">
                        <img src={`${BASE_URL}/${requestData?.product?.images[0]}`} alt="product" />
                    </figure>
                    <div className="content">
                    <h6 className="mt-8 fw-5">Request ID #{requestData?.id}</h6>
                    </div>
                </div>
                <div className="tf-grid-layout md-col-2 gap-15">
                    <div className="item">
                    <div className="text-2 text_black-2">Item</div>
                    <div className="text-2 mt_4 fw-6 text-capitalize">{requestData?.product?.name}</div>
                    </div>
                    <div className="item">
                    <div className="text-2 text_black-2">Price</div>
                    <div className="text-2 mt_4 fw-6">
                        {requestData?.admin_price === null ? 'Not Updated' : `${currency.icon}${requestData?.admin_price}`}
                    </div>
                    </div>
                    <div className="item">
                    <div className="text-2 text_black-2">Date</div>
                    <div className="text-2 mt_4 fw-6">{formatDate(requestData?.created_at)}</div>
                    </div>
                </div>
                <div className="widget-tabs style-has-border widget-order-tab">
                    <ul className="widget-menu-tab">
                    <li className="item-title active">
                        <span className="inner">Update</span>
                    </li>
                    </ul>
                    <div className="widget-content-tab">
                    <div className="widget-content-inner active">
                        <div className="widget-timeline">
                        <ul className="timeline">
                            <li>
                            <div className="timeline-badge success"></div>
                            <div className="timeline-box">
                                <NavLink className="timeline-panel" href="javascript:void(0);">
                                <div className="text-2 fw-6">Your message</div>
                                <span>{requestData?.message}</span>
                                </NavLink>
                                <p className='mt-2 mb-0'>
                                <strong>Reply by admin : </strong>
                                {requestData?.admin_message === null ? 'Pending' : requestData?.admin_message}
                                </p>
                                <p className='m-0'>
                                <strong>Date : </strong>{formatDate(requestData?.updated_at)}
                                </p>
                            </div>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="widget-content-inner">
                        {/* Additional content can go here */}
                    </div>
                    </div>
                </div>
                </div>
            </div>   
            </div>
        </div>
        </React.Fragment>
    );
}

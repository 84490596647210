// priceRequestSlice.js
import { createSlice, original } from '@reduxjs/toolkit';


const getInitialState = () => {
    let cartdata = localStorage.getItem('carts');
    if (cartdata) cartdata = JSON.parse(cartdata);
    else cartdata = [];

    return {
        data: cartdata,
        loading: false,
        error: null,
        message: null, // Added message state
    }
};


const cartSlice = createSlice({
    name: 'cart',
    initialState: getInitialState(),
    reducers: {
        addToCart: (state, action) => {
            console.log(action.payload);
            if (state.data.length) {
                const cartItem = state.data.find(x => x.id === action.payload.id);
                if (cartItem) cartItem.qty += 1;
                else state.data.push(action.payload);
                console.log("Qty INcreated");
            } else {
                state.data.push(action.payload);
                console.log("PUSHED");
            }
        },
        incrementQty: (state, action) => {
            if (state.data.length) {
                const cartItem = state.data.find(x => x.id === action.payload);
                if (cartItem) cartItem.qty += 1;
            }
        },
        decrementQty: (state, action) => {
            if (state.data.length) {
                const cartItem = state.data.find(x => x.id === action.payload);
                if (cartItem?.qty > 1) cartItem.qty -= 1;
            }
        },
        removeItem: (state, action) => {
            if (state.data.length) {
                const fIndex = state.data.findIndex(x => x.id === action.payload);
                if (fIndex !== -1) state.data.splice(fIndex, 1);
            }
        },
        clearCart: (state) => {
            state.data = [];
            state.loading = false;
            state.error = null;
            state.message = null; // Reset message state
            localStorage.removeItem('carts');
        },
    }
});

export const { addToCart, incrementQty, decrementQty, removeItem, clearCart } = cartSlice.actions;

export default cartSlice.reducer;

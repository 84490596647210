export const API_URL = process.env.REACT_APP_API_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;



export const SliderList = [
    {
        id: 1,
        heading: 'Essential Tools for Chefs',
        content: 'From chef knives to kitchen essentials, prepare like a professional.',
        image: "slider.jpg"
    },
    {
        id: 2,
        heading: 'Top-Quality Construction Tools',
        content: 'Equip yourself with durable, reliable tools for every construction task.',
        image: "slider-2.jpg"
    },
    {
        id: 3,
        heading: 'Medical Equipment for Hospitals',
        content: 'Advanced tools and equipment for nursing and healthcare professionals.',
        image: "slider-3.jpg"
    }
]


export const WhyChooseTab = [
    {
        id: 1,
        title: "quality products",
        content: "We are committed to offering only the best, ensuring that every product meets the highest standards of quality and safety.",
        cls: "leftside"
    },
    {
        id: 2,
        title: "Expertise",
        content: "With a deep understanding of the industries we serve, we carefully select products that truly enhance your training experience.",
        cls: "leftside"
    },
    {
        id: 3,
        title: "Customer Support",
        content: "Our team is here to assist you with any questions or needs, ensuring you have the right tools for your success.",
        cls: "leftside"
    }
];


export const OurProductTab = [
    {
        id: 1,
        title: "Trade Course Supplies",
        content: "We offer a comprehensive range of tools and equipment designed for hands-on learning in various trades. From carpentry to plumbing, our products are sourced from top manufacturers to ensure durability and performance.",
        cls: "rightside"
    },
    {
        id: 2,
        title: "Nursing Equipment",
        content: "Understanding the critical role that nurses play, we provide essential nursing supplies that are both practical and reliable. Our range includes everything from stethoscopes to patient care tools, designed to help you provide the best care possible.",
        cls: "rightside"
    },
    {
        id: 3,
        title: "Chef Equipment",
        content: "For those passionate about the culinary arts, we offer professional-grade kitchen equipment. Whether you’re learning the basics or refining your skills, our chef supplies are crafted to meet the demands of any kitchen environment.",
        cls: "rightside"
    }
];




export const TestimonialList = [
    {
        id: 1,
        name: "John Smith",
        designation: "Manager",
        image: "pic-1.jpg",
        message: "Great selection of chef essentials!  They have everything from uniforms to knives and spatulas.  Prices are fair, and the staff is knowledgeable.  Only downside is they don't have an online store, so you have to visit their shop in person.  Still, a good experience overall."
    },
    {
        id: 2,
        name: "Richel Kol",
        designation: "Manager",
        image: "pic-2.jpg",
        message: "Great selection of chef essentials!  They have everything from uniforms to knives and spatulas.  Prices are fair, and the staff is knowledgeable.  Only downside is they don't have an online store, so you have to visit their shop in person.  Still, a good experience overall."
      },
      
    ];
    
export const Productlist = [
        {
            id: 1,
            cat: "hospital",
            name: "Refurbished Hospital Beds",
            desc: "YA-D6-3 hospital bed has marked another generation for Medik’s ICU bed design",
            image: "bed.png",
        },
        {
            id: 2,
            cat: "hospital",
            name: "oxygen machine",
            desc: "YA-D6-3 hospital bed has marked another generation for Medik’s ICU bed design.",
            image: "oxygen-concentrator.png",
        },
        {
            id: 3,
            cat: "hospital",
            name: "diagnosis equipment",
            desc: "YA-D6-3 hospital bed has marked another generation for Medik’s ICU bed design...",
            image: "diagnosis-equipement.png",
        },
        {
            id: 4,
            cat: "hospital",
            name: "surgical equipment",
            desc: "YA-D6-3 hospital bed has marked another generation for Medik’s ICU bed design...",
            image: "surgical-tools.png",
        }
    
];
    // Here Product's Data with Category, subcategory and products //
export const ProductData = [
    {
      category: "Chef",
      image: 'collection-card-1.png',
      products: [
        { id: 1, name: "Chef Knife", image: "cutting-knifes.jpeg", description: "High-quality chef knife" },
        { id: 2, name: "Cutting Board", image: "cutting-board.jpg", description: "Durable wooden cutting board" }
      ],
    },
    {
      category: "Medical",
      image: 'collection-card-2.png',
      products: [
        { id: 3, name: "Lab Kit", image: "lab-kit.jpg", description: "Complete lab toolkit for students" },
        { id: 4, name: "Uniform", image: "doctor-uniform.png", description: "Complete lab toolkit for students" },
        { id: 5, name: "Stethoscope", image: "stethoscope.jpg", description: "Top-notch stethoscope" },
      ]
    },
    {
      category: "Trade",
      image: 'collection-card.png',
      products: [
        { id: 6, name: "Stethoscope", image: "stethoscope.jpg", description: "Top-notch stethoscope" },
      ]
    },
    {
      category: "Other Courses",
      image: 'collection-card-3.png',
      products: [
        { id: 7, name: "Stethoscope", image: "stethoscope.jpg", description: "Top-notch stethoscope" }
      ]
    }
    // other categories
  ];
  

  export const BlogList = [
    {
      id: 1,
      label: "Chef",
      title: 'Top Tools Every Chef Needs in Their Kitchen',
      author_name: 'John Doe',
      description: 'Explore the essential tools every professional chef needs in the kitchen, from high-quality knives to specialized utensils that ensure precision and efficiency in food preparation.',
      image: 'blog-1.jpg',
      date: '2024-09-25',
    },
    {
      id: 2,
      label: "Medical",
      title: 'The Latest Advancements in Hospital Equipment',
      author_name: 'Jane Smith',
      description: 'Discover the cutting-edge tools being used in modern hospitals, from advanced diagnostic devices to ergonomic surgical instruments that improve patient care and outcomes.',
      image: 'blog-2.jpg',
      date: '2024-09-20',
    },
    {
      id: 3,
      label: "Trade",
      title: 'Essential Construction Tools for Every Job Site',
      author_name: 'Mark Johnson',
      description: 'From power drills to safety gear, find out what tools are must-haves for construction workers to ensure efficiency and safety on the job.',
      image: 'blog-3.jpg',
      date: '2024-09-18',
    },
    {
      id: 4,
      label: "Trade",
      title: 'How to Choose the Best Protective Gear for Your Industry',
      author_name: 'Emily Davis',
      description: 'Selecting the right protective gear is crucial for safety in industries like healthcare, food service, and construction. Learn what to look for in top-rated equipment for maximum protection.',
      image: 'blog-4.jpg',
      date: '2024-09-15',
    },
  ];
  

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { fetchUserData, logout } from '../../../redux/slice/authSlice';
import '../../style/user-profile.css';


const UserProfile = () => {
    const dispatch = useDispatch();
    const { isLoggedIn, user, token } = useSelector((state) => state.auth);
    const [close, setClose] = useState(false);

    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        handleClose();
        navigate('/login');
    };

    // Bootstrap's offcanvas close handler
    const handleClose = () => {
        setTimeout(() => {
            document.querySelector('#user-profile-close').click();
        }, 200);
    }

    return (
        <div className={`offcanvas offcanvas-end canvas-search`} id="userProfile">
            <div className="canvas-wrapper">
                <header className="tf-search-head">
                    <div className="title fw-5">
                        <div className="profile-slide">
                            <img src="assets/images/profile/default_image.png" alt="" />
                            <div className="user-detail">
                                <p>{user ? user.name : "User"}</p>
                                <small>{user ? user.mobile : "Not provided"}</small>
                                <small>{user ? user.email : "Not provided"}</small>
                            </div>
                        </div>
                        <div className="profile-close close" id="user-profile-close" data-bs-toggle={"offcanvas"} data-bs-target={"#userProfile"}>
                            <span className="icon-close icon-close-popup" aria-label="Close"></span>
                        </div>
                    </div>
                    
                </header>
                <div className="canvas-body p-0">
                    <div className="tf-search-content">
                        <div className="tf-cart-hide-has-results">
                            <div className="tf-col-quicklink">
                                <div className="tf-search-content-title fw-5">Quick link</div>
                                <ul className="tf-quicklink-list">
                                    <li className="tf-quicklink-item">
                                        <NavLink to="/account/my-account-dashboard" className="" onClick={handleClose}>Your Account</NavLink>
                                    </li>
                                    <li className="tf-quicklink-item">
                                        <NavLink to="/account/my-account-orders" className="" onClick={handleClose}>Your Order</NavLink>
                                    </li>
                                    <li className="tf-quicklink-item">
                                        <NavLink to="/account/my-account-address" className="" onClick={handleClose}>Address</NavLink>
                                    </li>
                                    
                                    <li className="tf-quicklink-item">
                                        <NavLink to="#" onClick={handleLogout} className="">Logout</NavLink>
                                    </li>
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserProfile;

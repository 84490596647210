import React from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';

const BreadCrumb = ({title}) => {

    const location = useLocation();
    const { id } = useParams();


    return (
        <div className="tf-breadcrumb">
            <div className="container">
                <div className="tf-breadcrumb-wrap d-flex justify-content-between flex-wrap align-items-center">
                      <div className="tf-breadcrumb-list">
                        <NavLink to="/" className="text">Home</NavLink>
                        <i className="icon icon-arrow-right"></i>
                        {location.pathname.includes('/product-detail') ? (
                            <>
                                <NavLink to="/product" className="text">Products</NavLink>
                                <i className="icon icon-arrow-right"></i>
                                <span className="text">{title}</span>
                            </>
                        ) : (
                            <span className="text">{title}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BreadCrumb;

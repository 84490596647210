import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axiosInstance from '../../components/global/AxiosSetting';

// Async thunk to fetch products
export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async ({ search, category, category_slug, best_product, isLoggedIn }, { rejectWithValue }) => {
        try {
            // Construct the API URL with query parameters
            const params = {
                search,
                category,
                category_slug,
                best_product,
            };
            let productUrl = '/products';
            if (isLoggedIn) productUrl = `/user${productUrl}`
            const response = await axiosInstance.get(productUrl, { params });
            return response.data; // Assuming the data structure matches your provided format
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Product slice
const productSlice = createSlice({
    name: 'products',
    initialState: {
        products: { data: [] }, // Ensure products are initially set as an object with a data array
        dataLoaded: false,
        loading: false,
        error: null,
        search: '',              // State for the search term
        category: null,          // State for the selected category ID
        category_slug: '',       // State for the selected category slug
        best_product: '',     // Boolean to filter best products
    },
    reducers: {
        setSearch(state, action) {
            state.search = action.payload; // Update the search term
        },

        setCategory(state, action) {
            state.category = action.payload; // Update the selected category ID
        },
        setCategorySlug(state, action) {
            state.category_slug = action.payload; // Update the selected category slug
        },
        toggleBestProduct(state) {
            state.best_product = !state.best_product; // Toggle best product filter
        },
        clearFilters(state) {
            state.search = '';
            state.category = null;
            state.category_slug = '';
            state.best_product = false; // Clear all filters
        },
        updateWishlist(state, action) {
            const {productId, isWishlist} = action.payload;
            const product = state.products.data.find(x => x.id == productId);
            if (product) {
                product.wishtlist = isWishlist ? [{user_id: 12, product_id: productId}] : [];
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.loading = true; // Set loading state to true when fetching
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.loading = false; // Reset loading state
                console.log(action.payload.data);
                state.products.data = action.payload.data; // Store the products in 'data' array
                state.dataLoaded = true;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.loading = false; // Reset loading state
                state.error = action.payload; // Capture any error
            });
    },
});

// Export actions and reducer
export const { setSearch, setCategory, setCategorySlug, toggleBestProduct, clearFilters, updateWishlist } = productSlice.actions;
export default productSlice.reducer;

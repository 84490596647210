import React from 'react';

const TitleSection = () => {

    return (
        <section class="flat-spacing-9">
            <div class="container">
                <div class="flat-title my-0">
                    <span class="title">We are Man Training Supplies</span>
                    <p class="sub-title text_black-2">
                    Welcome to our specialized store, where we cater to the unique needs of professionals across various industries. Our collection offers premium products designed specifically for hotel chefs, medical professionals, and construction workers. From durable chef uniforms and kitchen essentials to reliable medical gear and top-notch construction equipment, we provide high-quality items that ensure comfort, safety, and performance. Our products are built to withstand the demands of your profession, making them the perfect choice for those who value durability and functionality in their work attire and tools.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default TitleSection;

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BASE_URL } from '../../global/GlobalVariable';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

const Categories = () => {
    const [enableLoop, setEnableLoop] = useState(false);
    const [slidesPerView, setSlidesPerView] = useState(4);

    const { categories, loading, error } = useSelector((state) => state.categories); // Get categories from state


    const setComponentSetting = () => {
        if (categories?.data?.length) {
            const slidesNum = categories.data.length < 4 ? categories.data.length : 4;
            const loop = categories.data.length >= slidesNum;
            setSlidesPerView(slidesNum);
            setEnableLoop(loop);
        }
    };

    useEffect(() => {
        setComponentSetting();
    }, [categories?.data]);

    if(loading) {
        return (
            <div className="d-flex align-items-center"> {/* Change class to className */}
                <strong>Loading...</strong>
                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
        )
    }
    
    if(error) {
        return (
            <div className="d-flex align-items-center">
                <strong>Error: {error.message || "Something went wrong"}</strong>
            </div>
        );
    }
     
    return (
        <section className="flat-spacing-11">
            <div className="container">
                <div className="position-relative">
                    <div className="flat-title flex-row justify-content-between px-0">
                        <span className="title fw-6 wow fadeInUp" data-wow-delay="0s">Popular Categories</span>
                    </div>
                    <div className="sw-pagination-wrapper">
                        <Swiper 
                            spaceBetween={30}
                            slidesPerView={slidesPerView}
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 3000 }}
                            speed={2000}
                            loop={enableLoop} // Enable or disable loop based on available slides
                            breakpoints={{
                                640: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                                },
                                768: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                                },
                                1024: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                                },
                            }}
                            className="swiper tf-sw-slideshow"
                        >
                            {
                                categories?.data?.length > 0 ? (
                                    categories.data.map((data) => {
                                        return (
                                            <SwiperSlide key={`cat-${data.id}`}>
                                                <div className="collection-item-v2 type-small hover-img">
                                                    <div className="collection-inner">
                                                        <div className="collection-image img-style radius-10">
                                                            <img
                                                                className="lazyload"
                                                                data-src={`${BASE_URL}/${data.image}`}
                                                                src={`${BASE_URL}/${data.image}`} // Fallback for non-lazy loaded images
                                                                alt="collection-img"
                                                                loading="lazy" // Native HTML lazy loading
                                                            />
                                                        </div>
                                                        <div className="collection-content">
                                                            <div className="top">
                                                                <h5 className="heading fw-5">{data.name}</h5>
                                                                <p className="subheading">{data.product.length} items</p> {/* Show total product count here */}
                                                            </div>
                                                            <div className="bottom">
                                                                <NavLink to={`/products/${data.slug}`} className="tf-btn collection-title hover-icon btn-light">
                                                                <span>Shop now</span>
                                                                    <i className="icon icon-arrow1-top-left"></i>
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                        {/* ... */}
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })
                                ) : (
                                    <p>No Categories available.</p>
                                )
                            }
                            </Swiper>
                        
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Categories;

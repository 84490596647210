import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../components/global/AxiosSetting';

// Async thunk to fetch website settings
export const fetchWebsiteSettings = createAsyncThunk(
    'website/fetchWebsiteSettings',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/website-setting`);
            return response.data.data; // Assuming the data comes under 'data'
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);


// Initial state in websiteSlice
const initialState = {
    data: {
        id: null,
        address: '',
        phone: '',
        email: '',
        facebook: '',
        twitter: '',
        instagram: '',
        whatsapp: '',
        map: '',
        created_at: '',
        updated_at: '',
    },
    dataLoaded: false,
    loading: false,
    error: null,
};

// Define the slice
const websiteSlice = createSlice({
    name: 'website',
    initialState,   
    reducers: {
        resetWebsiteState: (state) => {
            state.data = initialState.data;
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWebsiteSettings.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWebsiteSettings.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload; // Populate state with fetched data
                state.error = null;
                state.dataLoaded = true;
            })
            .addCase(fetchWebsiteSettings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch website settings';
            });
    },
});

export const { resetWebsiteState } = websiteSlice.actions;

export default websiteSlice.reducer;

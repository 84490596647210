import React from 'react';
import { NavLink } from 'react-router-dom';


const CanvaSearch = () => {


    return (
        <div className="offcanvas offcanvas-end canvas-search " id="canvaSearchModal">
            <div className="canvas-wrapper">
                <header className="tf-search-head">
                    <div className="title fw-5">
                        Search our site
                        <div className="close">
                            <span className="icon-close icon-close-popup" data-bs-dismiss="offcanvas" aria-label="Close"></span>
                        </div>
                    </div>
                    <div className="tf-search-sticky">
                        <form className="tf-mini-search-frm">
                            <fieldset className="text">
                                <input type="text" placeholder="Search" className="" name="text" tabindex="0" value="" aria-required="true" required=""/>
                            </fieldset>
                            <button className="" type="submit"><i className="icon-search"></i></button>
                        </form>
                    </div>
                </header>
                <div className="canvas-body p-0">
                    <div className="tf-search-content">
                        <div className="tf-cart-hide-has-results">
                            <div className="tf-col-quicklink">
                                <div className="tf-search-content-title fw-5">Quick link</div>
                                <ul className="tf-quicklink-list">
                                    <li className="tf-quicklink-item">
                                        <NavLink to="shop-default.html" className="">Medical</NavLink>
                                    </li>
                                    <li className="tf-quicklink-item">
                                        <NavLink to="shop-default.html" className="">Construction</NavLink>
                                    </li>
                                    <li className="tf-quicklink-item">
                                        <NavLink to="shop-default.html" className="">Hotel</NavLink>
                                    </li>
                                    <li className="tf-quicklink-item">
                                        <NavLink to="shop-default.html" className="">All</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="tf-col-content">
                                <div className="tf-search-content-title fw-5">Need some inspiration?</div>
                                <div className="tf-search-hidden-inner">
                                    <div className="tf-loop-item">
                                        <div className="image">
                                            <NavLink to="product-detail.html">
                                                <img src="images/products/white-3.jpg" alt=""/>
                                            </NavLink>
                                        </div>
                                        <div className="content">
                                            <NavLink to="product-detail.html">Cotton jersey top</NavLink>
                                            <div className="tf-product-info-price">
                                                <div className="compare-at-price">₹10.00</div>
                                                <div className="price-on-sale fw-6">₹8.00</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-loop-item">
                                        <div className="image">
                                            <NavLink to="product-detail.html">
                                                <img src="images/products/white-2.jpg" alt=""/>
                                            </NavLink>
                                        </div>
                                        <div className="content">
                                            <NavLink to="product-detail.html">Mini crossbody bag</NavLink>
                                            <div className="tf-product-info-price">
                                                <div className="price fw-6">₹18.00</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-loop-item">
                                        <div className="image">
                                            <NavLink to="product-detail.html">
                                                <img src="images/products/white-1.jpg" alt=""/>
                                            </NavLink>
                                        </div>
                                        <div className="content">
                                            <NavLink to="product-detail.html">Oversized Printed T-shirt</NavLink>
                                            <div className="tf-product-info-price">
                                                <div className="price fw-6">₹18.00</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CanvaSearch;

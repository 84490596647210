import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import required modules
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { SliderList } from '../global/GlobalVariable';
import { NavLink } from 'react-router-dom';

const Slider = () => {
    return (
        <React.Fragment>
            <div className="tf-slideshow slider-giftcard slider-effect-fade position-relative">
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    // spaceBetween={50}
                    slidesPerView={1}
                    // navigation // enables next/prev buttons
                    pagination={{ clickable: true }} // enables pagination dots
                    autoplay={{ delay: 3000 }} // autoplay with a delay
                    speed={2000} // slows down transition between slides (2 seconds)
                    loop={true} // enable loop mode
                    className="swiper tf-sw-slideshow"
                >
                    {
                        SliderList.map((data, index) => (
                            <SwiperSlide key={`slider-${index}`}>
                                <div className="wrap-slider">
                                    <img className="lazyload" data-src={`assets/images/slider/${data.image}`} src="/assets/images/slider/giftCard_slide-1.jpg" alt="gift-card-slideshow-01" />
                                    <div className="box-content">
                                        <div className="container">
                                            <h2 className="fade-item fade-item-1 heading text-white">{data.heading}</h2>
                                            <p className="fade-item fade-item-2 text-white">{data.content}</p>
                                            <NavLink to="/product" className="fade-item fade-item-3 tf-btn btn-primary animate-hover-btn btn-xl radius-3">
                                                <span>Shop collection</span><i className="icon icon-arrow-right"></i>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                        ))
                    }
                    {/* Add other SwiperSlide components here */}
                </Swiper>

                <div className="wrap-pagination sw-absolute-2">
                    <div className="container">
                        <div className="sw-dots style-2 dots-white sw-pagination-slider"></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Slider;

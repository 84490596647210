import React, {useEffect, useState} from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchOrderData } from '../../../redux/slice/orderSlice';
import { currency } from '../../../utils/currency';


const Orders = () => {
    const dispatch = useDispatch();
    const {orders, loading} = useSelector(state => state.order);

    useEffect(() => {
        dispatch(fetchOrderData());
    }, []);

    return (
        <React.Fragment>
            <Col lg="9">
                <div className="my-account-content account-order">
                    <div className="wrap-account-order">
                        <table>
                            <thead>
                                <tr>
                                    <th className="fw-6">Order</th>
                                    <th className="fw-6">Date</th>
                                    <th className="fw-6">Status</th>
                                    <th className="fw-6">Total</th>
                                    <th className="fw-6">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map(x => <tr className="tf-order-item">
                                    <td>
                                        #{x.order_no}
                                    </td>
                                    <td>
                                        {new Date(x.created_at).toLocaleDateString()}
                                    </td>
                                    <td>
                                        {x.status}
                                    </td>
                                    <td>
                                        {currency.icon}{x.all_total} for {x.items.length} items
                                    </td>
                                    <td>
                                        <NavLink to={`/account/my-account-orders-details/${x.order_no}`} className="tf-btn btn-fill animate-hover-btn rounded-0 justify-content-center">
                                            <span>View</span>
                                        </NavLink>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Col>
        </React.Fragment>
    )
};

export default Orders;

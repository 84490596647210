import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withSwal } from 'react-sweetalert2';
// import { resetPriceRequest, sendPriceRequest } from '../../../redux/slice/priceRequestSlice';
import { postProductPrice } from '../../global/server-api';
import { fetchPriceRequest } from '../../../redux/slice/priceRequestSlice';

const PriceRequestForm = ({productId, swal}) => {
    const dispatch = useDispatch();
    const [count, setCount] = useState(1);
    const [loading, setLoading] = useState(false);

    const {isLoggedIn} = useSelector(store => store.auth);
    
    const { register, handleSubmit, setValue, reset, formState: { errors }} = useForm({
        defaultValues: {
            qty: 1,
            product_id: productId,
            message: ''
        }
    });

    useEffect(() => {
        setValue("qty", count);
    }, [count]);

    useEffect(() => {
        setValue("product_id", productId);
    }, [productId]);


    const handleIncrement = () => {
        setCount(count + 1);  // Increment count correctly
    };

    const handleDecrement = () => {
        if (count > 1) {
            setCount(count - 1);
        }
    };
   
    const onSubmit = async (data) => {
        if (loading) return;

        setLoading(true);

        try {
            const response = await postProductPrice(data);
            if (response.data) {
                console.log(response.data);
                successMsg(response.data.successMsg);
                reset({qty: 1, product_id: productId, message: ''});
                dispatch(fetchPriceRequest({}));
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error?.response?.data) {
                errorMsg(error.response.data.message);
            }
        }
    }

    const successMsg = message => {
        swal.fire({
            title: 'Success!',
            text: message || 'Price request sent successfully!',
            icon: 'success',
        });
    }

    const errorMsg = message => {
        swal.fire({
            title: 'Error!',
            text: message || 'Failed to send request.',
            icon: 'error',
        });
    };


    return (
         
        <div className="tf-product-info-buy-button">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-100">
                    <div className="tf-product-info-quantity mb-15">
                        <div className="quantity-title fw-6">Quantity</div>
                        <div className="wg-quantity">
                            <span className="btn-quantity minus-btn" onClick={handleDecrement}>-</span>
                                <input 
                                    type="text" 
                                    name="quantity"
                                    readOnly 
                                    {...register('qty', { required: true })} // Registering quantity
                                />
                            <span className="btn-quantity plus-btn" onClick={handleIncrement}>+</span>
                            {errors.qty && <span className="text-danger">Quantity is required</span>}
                        </div>
                    </div>
                    <br />
                    <div className="tf-field style-1 mb_10">
                        <textarea 
                            className='tf-field-input tf-textarea' 
                            placeholder="Message *"
                            {...register('message', { required: true })} // Registering message
                            id=""></textarea>
                        {errors.message && <span className="text-danger">Message is required</span>}
                    </div>
                    {/* Hidden field for product_id */}
                    <input type="hidden" defaultValue={productId} {...register('product_id')} />
                </div>
                <div className="w-100">
                    {isLoggedIn ? 
                        <button type='submit' className="btns-full">
                            { loading ? 'Sending Request' : 'Request Price'}
                        </button>
                    :   
                        <NavLink to="/login" className="btns-full">Request Price</NavLink>
                    }
                </div>
            </form>
        </div>
    );
}

export default  withSwal(PriceRequestForm);

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../components/global/AxiosSetting';

// Async thunk to fetch categories with their products
export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async () => {
    const response = await axiosInstance.get(`/category-products`);
    return response.data; // Return the response data (categories with products)
  }
);

const categorySlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [], // Rename state to 'categories'
    dataLoaded: false,
    loading: false, // Loading state
    error: null,    // Error state
  },
  reducers: {
    toggleProductList: (state) => {
      // You can implement product list toggle logic if necessary
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload; // Save fetched categories in 'categories' state
        state.dataLoaded = true;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Handle errors
      });
  },
});

// Export the actions and reducer
export const { toggleProductList } = categorySlice.actions;
export default categorySlice.reducer;

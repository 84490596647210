import React from 'react';
import { NavLink } from 'react-router-dom';
import { BlogList } from '../global/GlobalVariable';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

const Blog = () => {

    return (
        <section className="flat-spacing-14">
            <div className="container">
                <div className="flat-title wow fadeInUp" data-wow-delay="0s">
                    <span className="title fw-6">Blogs post</span>
                </div>
                <div className="hover-sw-nav view-default hover-sw-5">
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={30}
                            slidesPerView={3}
                            navigation={{
                                nextEl: '.nav-next-slider',
                                prevEl: '.nav-prev-slider',
                            }}
                            pagination={{ clickable: false }}
                            breakpoints={{
                                768: { slidesPerView: 3 },
                                576: { slidesPerView: 2 }
                            }}
                        >
                        {
                            BlogList.map((data,index) => (
                                <SwiperSlide key={`blog-list${index}`}>
                                        <div className="blog-article-item">
                                            <div className="article-thumb radius-10">
                                                <NavLink to={`/blog-detail/${data.id}`}>
                                                    <img className="lazyload" data-src={`assets/images/blog/${data.image}`} src={`assets/images/blog/${data.image}`} alt="img-blog"/>
                                                </NavLink>
                                                <div className="article-label">
                                                    <NavLink to="shop-collection-list" className="tf-btn style-2 btn-fill radius-3 animate-hover-btn">{data.label}</NavLink>
                                                </div>
                                            </div>
                                            <div className="article-content">
                                                <div className="article-title">
                                                    <NavLink to={`/blog-detail/${data.id}`} className="">{data.title}</NavLink>
                                                </div>
                                                <div className="article-btn">
                                                    <NavLink to={`/blog-detail/${data.id}`} className="tf-btn btn-line fw-6">Read more<i className="icon icon-arrow1-top-left"></i></NavLink>
                                                </div>
                                            </div>
                                        </div>

                                </SwiperSlide>
                            ))
                        }
                            
                            <div className="nav-sw nav-next-slider nav-next-recent box-icon w_46 round"><span className="icon icon-arrow-left"></span></div>
                            <div className="nav-sw nav-prev-slider nav-prev-recent box-icon w_46 round"><span className="icon icon-arrow-right"></span></div>
                        </Swiper>
                    </div>
                
            </div>
        </section>
    );
}

export default Blog;

import React, { useEffect } from 'react';
import InnerBanner from '../elements/InnerBanner';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, signup } from '../../redux/slice/authSlice'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';

export default function SignUpPage() {

    const { register, handleSubmit, formState: { errors, isSubmitting }} = useForm();


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, isLoggedIn } = useSelector(state => state.auth);

    useEffect(() => {
        // Redirect if the user is already logged in
        if (isLoggedIn) {
            navigate('/login');
        }
    }, [isLoggedIn]);

    const onSubmit = async (data) => {
        const response = await dispatch(signup(data));
        console.log({ response });
        
        if (response.payload.successMsg) {
            Swal.fire({
                title: 'Success',
                icon: 'success',
                text: response.payload.successMsg,
                confirmButtonText: 'OK',
            }).then(() => {
                navigate('/login'); // Redirect to login after successful registration
            });
        } else {
            Swal.fire('Error', response.payload.message || 'Registration failed.', 'error');
        }
    };


  return (
    <React.Fragment>
        <InnerBanner title="Register"/>

        <section className="flat-spacing-10">
            <div className="container">
                <div className="form-register-wrap">
                    <div className="flat-title align-items-start gap-0 mb_30 px-0">
                        <h5 className="mb_18">Register</h5>
                        <p className="text_black-2">Sign up for early Sale access plus tailored new arrivals, trends and promotions. To opt out, click unsubscribe in our emails</p>
                    </div>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="tf-field style-1 mb_15">
                                <input 
                                    className="tf-field-input tf-input" 
                                    placeholder=" " 
                                    type="text" 
                                    {...register("first_name", { required: "First Name is required" })} 
                                />
                                <label className="tf-field-label" htmlFor="">First Name</label>
                                {errors.first_name && <p className="text-danger mb-3">{errors.first_name.message}</p>}
                            </div>
                            
                            <div className="tf-field style-1 mb_15">
                                <input 
                                    className="tf-field-input tf-input" 
                                    placeholder=" " 
                                    type="text" 
                                    {...register("last_name", { required: "Last Name is required" })} 
                                />
                                <label className="tf-field-label" htmlFor="">Last Name</label>
                                {errors.last_name && <p className="text-danger mb-3">{errors.last_name.message}</p>}
                            </div>

                            <div className="tf-field style-1 mb_15">
                                <input 
                                    className="tf-field-input tf-input" 
                                    placeholder=" " 
                                    type="email" 
                                    {...register("email", { 
                                        required: "Email is required", 
                                        pattern: { 
                                            value: /^\S+@\S+$/i, 
                                            message: "Invalid email address" 
                                        }
                                    })} 
                                />
                                <label className="tf-field-label" htmlFor="">Email *</label>
                                {errors.email && <p className="text-danger mb-3">{errors.email.message}</p>}
                            </div>

                            <div className="tf-field style-1 mb_15">
                                <input 
                                    className="tf-field-input tf-input" 
                                    placeholder=" " 
                                    type="password" 
                                    {...register("password", { required: "Password is required" })} 
                                />
                                <label className="tf-field-label" htmlFor="">Password *</label>
                                {errors.password && <p className="text-danger mb-3">{errors.password.message}</p>}
                            </div>

                            <div className="tf-field style-1 mb_15">
                                <input 
                                    className="tf-field-input tf-input" 
                                    placeholder=" " 
                                    type="password"  
                                    {...register("password_confirmation", { required: "Confirm password is required"})}
                                />
                                <label className="tf-field-label" htmlFor="">Confirm Password *</label>
                                {errors.password_confirmation && <p className="text-danger mb-3">{errors.password_confirmation.message}</p>}
                            </div>

                            <div className="mb_20">
                                <button 
                                    type="submit" 
                                    className="tf-btn w-100 radius-3 btn-fill animate-hover-btn justify-content-center"
                                    disabled={isSubmitting || loading}
                                >
                                    {loading ? 'Registering...' : 'Register'}
                                </button>

                            </div>

                            <div className="text-center">
                                <NavLink to="/login" className="tf-btn btn-line">Already have an account? Log in here<i className="icon icon-arrow1-top-left"></i></NavLink>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
  )
}

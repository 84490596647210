import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance, {updateToken} from '../../components/global/AxiosSetting';



export const signup = createAsyncThunk('auth/signup', async (userData) => {
    const response = await axiosInstance.post(`/signup`, userData);
    return response.data; // Adjust according to your API response
});

export const login = createAsyncThunk('auth/login', async (credentials) => {
    const response = await axiosInstance.post(`/login`, credentials);
    return response.data; // Adjust according to your API response
});

export const fetchUserData = createAsyncThunk('auth/fetchUserData', async () => {
    const response = await axiosInstance.get(`/user`);
    console.log("User__:", response.data);
    return response.data; // Adjust according to your API response
});

export const logout = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`/log-out`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});



const getInitialState = () => {
    let token = localStorage.getItem('token');
    let user = null;
    if (token) {
        updateToken(token);
        user = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
        } else {
            token = null;
        }
    }
    
    return {
      user, 
      token,
      isLoggedIn: user ? true : false, 
      loading: false, 
      error: null
    }
};



const authSlice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        clearAuth(state) {
            state.user = null;
            state.token = null;
            state.isLoggedIn = false;
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            updateToken("");
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signup.fulfilled, (state, action) => {
                // state.token = action.payload.token;
                // state.user = action.payload.user;
                // state.isLoggedIn = true;
                // updateToken(action.payload.token);
                // localStorage.setItem('token', action.payload.token);
                // localStorage.setItem('user', JSON.stringify(action.payload.data));
            })
            .addCase(login.fulfilled, (state, action) => {
                state.token = action.payload.token;
                state.user = action.payload.user;
                state.isLoggedIn = true;
                updateToken(action.payload.token);
                localStorage.setItem('token', action.payload.token);
                localStorage.setItem('user', JSON.stringify(action.payload.data));
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                console.log("FETCH_USER_PAYLOAD: ", action.payload);
                state.user = action.payload;
                state.isLoggedIn = true; // Update if needed
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                state.error = action.error.message;
            })
            // Handle async logout
            .addCase(logout.fulfilled, (state) => {
                state.user = null;
                state.token = null;
                state.isLoggedIn = false;

                
                // console.log("LOG-OUT", state);
                updateToken("");
                // Clear localStorage
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                // No need to remove localStorage here because it's already done in the async thunk
            })
            .addCase(logout.rejected, (state, action) => {
                state.error = action.error.message;
            })
            .addCase(signup.rejected, (state, action) => {
                state.error = action.error.message;
            })
            .addCase(login.rejected, (state, action) => {
                state.error = action.error.message;
            });
    },
});

export const { clearAuth } = authSlice.actions;
export default authSlice.reducer;

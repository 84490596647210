import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchWebsiteSettings } from '../../redux/slice/websiteSlice';
import Skeleton from 'react-loading-skeleton';

const Footer = () => {

    const dispatch = useDispatch();
    const { data, loading } = useSelector((state) => state.website || {  });

    // useEffect(() => {
    //     dispatch(fetchWebsiteSettings());
    // },[]);

    // console.log(data);
    
    if(loading){
        return <Skeleton count={6} height={50}/>
    }

    return (
        <footer id="footer" className="footer md-pb-70">
        <div className="footer-wrap">
            <div className="footer-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-md-6 col-12">
                            <div className="footer-infor">
                                <div className="footer-logo">
                                    <NavLink to="/">
                                        <div className="footer-heading footer-heading-desktop">
                                            <img src="assets/images/logo/logo.png" alt=""/>
                                        </div>
                                        <div className="footer-heading footer-heading-moblie">
                                            <h6>Man Training Supplies</h6>
                                        </div>
                                    </NavLink>
                                </div>
                                <ul>
                                    <li>
                                        <p>{data.address}</p>
                                    </li>
                                    <li>
                                        <p>Email: <NavLink to={`mailto:${data.email}`}>{data.email}</NavLink></p>
                                    </li>
                                    <li>
                                        <p>Phone: {data.phone ? data.phone.split(', ').map(x => <NavLink key={`${x}`} to={`tel:${x}`} style={{marginRight: '10px'}}>{x}</NavLink>) : null}
                                        </p>
                                    </li>
                                </ul>
                                <NavLink to={data.map} className="tf-btn btn-line">Get direction<i className="icon icon-arrow1-top-left"></i></NavLink>
                                <ul className="tf-social-icon d-flex gap-10">
                                    <li><NavLink to={data.facebook} className="box-icon w_34 round social-facebook social-line"><i className="icon fs-14 icon-fb"></i></NavLink></li>
                                    <li><NavLink to={data.twitter} className="box-icon w_34 round social-twiter social-line"><i className="icon fs-12 icon-Icon-x"></i></NavLink></li>
                                    <li><NavLink to={data.instagram} className="box-icon w_34 round social-instagram social-line"><i className="icon fs-14 icon-instagram"></i></NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-12 footer-col-block">
                            <div className="footer-heading footer-heading-desktop">
                                <h6>Help</h6>
                            </div>
                            <div className="footer-heading footer-heading-moblie">
                                <h6>Help</h6>
                            </div>
                            <ul className="footer-menu-list tf-collapse-content">
                                <li>
                                    <NavLink to="/privacy-policy" className="footer-menu_item">Privacy Policy</NavLink>
                                </li>
                                <li> 
                                    <NavLink to="/terms-conditions" className="footer-menu_item">Terms &amp; Conditions</NavLink>
                                </li>
                                <li> 
                                    <NavLink to="/faq" className="footer-menu_item">FAQ’s</NavLink>
                                </li>
                                <li> 
                                    <NavLink to="/wishlist" className="footer-menu_item">My Wishlist</NavLink>
                                </li>
                            </ul>
                        </div>
                        
                        <div className="col-xl-6 col-md-6 col-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3148.887841383611!2d144.62328617588884!3d-37.88630557195746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzfCsDUzJzEwLjciUyAxNDTCsDM3JzMzLjEiRQ!5e0!3m2!1sen!2sin!4v1730532907378!5m2!1sen!2sin" width="100%" height="100%" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-bottom-wrap d-flex gap-20 flex-wrap justify-content-between align-items-center">
                                <div className="footer-menu_item">© 2024 Man Training Supplies. All Rights Reserved</div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    );
}

export default Footer;

import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";


export function GuestGuard({ children }) {
    const { isLoggedIn } = useSelector((state) => state.auth);
    const token = localStorage.getItem('token');

    // If logged in, redirect away from the login/signup page
    if (isLoggedIn || token) {
        return <Navigate to="/" />;
    }

    return children;
}

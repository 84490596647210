import React from 'react';

const Tabs = ({product}) => {

    // console.log(product);
    return (
        <section className="flat-spacing-17 pt_0">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="widget-tabs style-has-border">
                            <ul className="widget-menu-tab" id="myTab" role="tablist">
                                <li className="item-title active" role="presentation">
                                    <span className="inner" id="description-tab" data-bs-toggle="tab" data-bs-target="#description-tab-pane" type="button" role="tab" aria-controls="description-tab-pane" aria-selected="true">Description</span>
                                </li>
                              
                            </ul>
                            <div className="widget-content-tab" id="myTabContent">
                                <div className="widget-content-inner active" id="description-tab-pane" role="tabpanel" aria-labelledby="description-tab" tabindex="0">
                                    <div className="">
                                        <p className="mb_30">
                                            <div dangerouslySetInnerHTML={{ __html: product.description }}></div> 
                                            
                                        </p>
                                     
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Tabs;

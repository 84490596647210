import React, { useEffect } from 'react';
import InnerBanner from '../elements/InnerBanner';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import { BASE_URL, BlogList } from '../global/GlobalVariable';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogData, fetchSingleBlog } from '../../redux/slice/blogSlice';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Preloader from '../include/Preloader';

const BlogDetail = () => {

    const { slug } = useParams();

    const dispatch = useDispatch();
    const { blogs, singleBlog, loading } = useSelector((state) => state.blog);

    useEffect(() => {
        if (slug) {
            dispatch(fetchSingleBlog(slug));
            if (!blogs?.length) dispatch(fetchBlogData());
        }
    }, [slug]);
    

    // Get the index of the current blog in BlogList array
    const currentIndex = blogs.findIndex((blogItem) => blogItem.slug === slug);


     // Get previous and next blogs based on the index
     const prevBlog = currentIndex > 0 ? blogs[currentIndex - 1] : null;
     const nextBlog = currentIndex < blogs.length - 1 ? blogs[currentIndex + 1] : null;

    console.log("SingleBlog__",singleBlog);
    console.log("Blog__",blogs);


    if(loading || !singleBlog ){
        return <Preloader/>
    }


    return (
        <React.Fragment>
            <InnerBanner title="Blog Detail"/>
            
            {/* blog detail  */}
            <section className="blog-detail">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="blog-detail-main">
                                <div className="blog-detail-main-heading">
                                    <ul className="tags-lists justify-content-center">
                                        <li>
                                            {singleBlog?.category?.name ? (
                                                <NavLink to="#" className="tags-item">{singleBlog?.category?.name}</NavLink>
                                            ) : (
                                                <span className="tags-item">No Category</span>
                                            )}
                                        </li>
                                    </ul>
                                    <div className="title">{singleBlog?.title || "No Title"}</div>
                                    <div className="meta">
                                        by <span>{singleBlog?.author_name ? singleBlog?.author_name : "admin"}</span> 
                                        on <span>{singleBlog?.date || "Unknown Date"}</span>
                                    </div>
                                    <div className="image">
                                        {singleBlog?.image ? (
                                            <img className="lazyload" data-src={`${BASE_URL}/${singleBlog.image}`} src={`${BASE_URL}/${singleBlog.image}`} alt="" />
                                        ) : (
                                            <Skeleton height={200} />
                                        )}
                                    </div>
                                </div>

                                <blockquote>
                                    <div className="icon">
                                        <img src="assets/images/item/quote.svg" alt=""/>
                                    </div>
                                    <div className="text">{singleBlog.description}</div>
                                </blockquote>
                                
                                <div className="bot d-flex justify-content-between flex-wrap align-items-center">
                                    <ul className="tags-lists">
                                        <li>
                                            <NavLink to="#" className="tags-item"><span>{singleBlog?.category?.name}</span></NavLink>
                                        </li>
                                    </ul>
                                    {/* <div className="d-flex align-items-center gap-20">
                                        <p>Share:</p>
                                        <ul className="tf-social-icon d-flex style-default">
                                            <li><NavLink to="#" className="box-icon round social-facebook border-line-black"><i className="icon fs-14 icon-fb"></i></NavLink></li>
                                            <li><NavLink to="#" className="box-icon round social-twiter border-line-black"><i className="icon fs-12 icon-Icon-x"></i></NavLink></li>
                                            <li><NavLink to="#" className="box-icon round social-instagram border-line-black"><i className="icon fs-14 icon-instagram"></i></NavLink></li>
                                            <li><NavLink to="#" className="box-icon round social-tiktok border-line-black"><i className="icon fs-14 icon-tiktok"></i></NavLink></li>
                                            <li><NavLink to="#" className="box-icon round social-pinterest border-line-black"><i className="icon fs-14 icon-pinterest-1"></i></NavLink></li>
                                        </ul>
                                    </div> */}
                                </div>

                                {/* Dynamic Previous and Next navigation */}
                                <div className="tf-article-navigation">
                                    <div className="item position-relative d-flex w-100 prev">
                                    {
                                        prevBlog ? (
                                            <NavLink to={`/blog-detail/${prevBlog.slug}`} className="icon">
                                                <i className="icon-arrow-left"></i>
                                            </NavLink>
                                        ) : (
                                            <span className="icon icon-disabled">
                                                <i className="icon-arrow-left"></i>
                                            </span>
                                        )
                                    }
                                    {
                                        prevBlog && (
                                            <div className="inner">
                                                <NavLink to={`/blog-detail/${prevBlog.slug}`}>PREVIOUS</NavLink>
                                                <h6>
                                                    <NavLink to={`/blog-detail/${prevBlog.slug}`}>{prevBlog.title}</NavLink>
                                                </h6>
                                            </div>
                                        )
                                    }
                                    </div>
                                    <div className="item position-relative d-flex w-100 justify-content-end next">
                                    {
                                        nextBlog ? (
                                            <div className="inner text-end">
                                                <NavLink to={`/blog-detail/${nextBlog.slug}`}>NEXT</NavLink>
                                                <h6>
                                                    <NavLink to={`/blog-detail/${nextBlog.slug}`}>{nextBlog.title}</NavLink>
                                                </h6>
                                            </div>
                                        ) : (
                                            <span className="inner text-end disabled">
                                                <span>NEXT</span>
                                            </span>
                                        )
                                    }
                                    {
                                        nextBlog ? (
                                            <NavLink to={`/blog-detail/${nextBlog.slug}`} className="icon">
                                                <i className="icon-arrow-right"></i>
                                            </NavLink>
                                        ) : (
                                            <span className="icon icon-disabled">
                                                <i className="icon-arrow-right"></i>
                                            </span>
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Related article */}
            <section className="mb_30">
                <Container>
                    <div className="flat-title">
                        <h5 className="">Related Articles</h5>
                    </div>
                    <div className="hover-sw-nav view-default hover-sw-5">
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={30}
                            slidesPerView={3}
                            navigation={{
                                nextEl: '.nav-next-slider',
                                prevEl: '.nav-prev-slider',
                            }}
                            pagination={{ clickable: false }}
                            breakpoints={{
                                768: { slidesPerView: 3 },
                                576: { slidesPerView: 2 }
                            }}
                        >
                        {
                            blogs.map((data,index) => (
                                <SwiperSlide key={`blog-list${index}`}>
                                        <div className="blog-article-item">
                                            <div className="article-thumb radius-10">
                                                <NavLink to={`/blog-detail/${data.slug}`}>
                                                    <img className="lazyload" data-src={`${BASE_URL}/${data.image}`} src={`${BASE_URL}/${data.image}`} alt="img-blog"/>
                                                </NavLink>
                                                <div className="article-label">
                                                    <NavLink to="shop-collection-list" className="tf-btn style-2 btn-fill radius-3 animate-hover-btn">{data.category.name}</NavLink>
                                                </div>
                                            </div>
                                            <div className="article-content">
                                                <div className="article-title">
                                                    <NavLink to={`/blog-detail/${data.slug}`} className="">{data.title}</NavLink>
                                                </div>
                                                <div className="article-btn">
                                                    <NavLink to={`/blog-detail/${data.slug}`} className="tf-btn btn-line fw-6">Read more<i className="icon icon-arrow1-top-left"></i></NavLink>
                                                </div>
                                            </div>
                                        </div>

                                </SwiperSlide>
                            ))
                        }
                            
                            <div className="nav-sw nav-next-slider nav-next-recent box-icon w_46 round"><span className="icon icon-arrow-left"></span></div>
                            <div className="nav-sw nav-prev-slider nav-prev-recent box-icon w_46 round"><span className="icon icon-arrow-right"></span></div>
                        </Swiper>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default BlogDetail;

import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { logout } from '../../../redux/slice/authSlice';
import { updatePassword } from '../../../redux/slice/updatePasswordSlice';



export default function AccountDetail() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, success } = useSelector((state) => state.updatePassword);

    const { register, handleSubmit, formState: { errors } } = useForm();

    // Show success message
    const showConfirm = (message) => {
        Swal.fire({
            title: 'Success',
            icon: 'success',
            text: message,
            confirmButtonText: 'OK',
        }).then(() => {
            navigate('/login'); // Go back
        });
    };

    // Show error message
    const showError = (message) => {
        Swal.fire({
            title: 'Error',
            icon: 'error',
            text: message,
            confirmButtonText: 'OK',
        });
    };

    // Handle form submission
    const onSubmit = (data) => {
        dispatch(updatePassword(data)).then((response) => {
            if (response.meta.requestStatus === "fulfilled") {
                dispatch(logout());
                showConfirm(response.payload.message);
            } else {
                console.log(response);
                showError(response.payload);
            }
        });
    };

    // console.log(success);

    return (
        <React.Fragment>
            <div className="my-account-content account-edit">
                <div className="">
                    <form onSubmit={handleSubmit(onSubmit)} className="">
                        <h6 className="mb_20">Password Change</h6>
                        <div className="tf-field style-1 mb_30">
                            <input className="tf-field-input tf-input" placeholder=" " type="password" id="property4" {...register('old_password', { required: "Old Password is required" })}/>
                            <label className="tf-field-label fw-4 text_black-2" for="property4">Old password</label>
                            {errors.old_password && <p className="text-danger mb-3">{errors.old_password.message}</p>}
                        </div>
                        <div className="tf-field style-1 mb_30">
                            <input className="tf-field-input tf-input" placeholder=" " type="password" id="property5" {...register('password', { required: "Password is required" })}/>
                            <label className="tf-field-label fw-4 text_black-2" for="property5">New password</label>
                            {errors.password && <p className="text-danger mb-3">{errors.password.message}</p>}
                        </div>
                        <div className="tf-field style-1 mb_30">
                            <input className="tf-field-input tf-input" placeholder=" " type="password" id="property6" {...register('password_confirmation', { required: "Confirmed Password is required" })}/>
                            <label className="tf-field-label fw-4 text_black-2" for="property6">Confirm password</label>
                            {errors.password_confirmation && <p className="text-danger mb-3">{errors.password_confirmation.message}</p>}
                        </div>
                        <div className="mb_20">
                            <button type="submit" className="tf-btn w-100 radius-3 btn-fill animate-hover-btn justify-content-center">
                                { loading ? 'Saving...' : "Save Changes" }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

import React from 'react';
import InnerBanner from '../elements/InnerBanner';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { withSwal } from 'react-sweetalert2';
import { login } from '../../redux/slice/authSlice';

const LoginPage = (props) => {
    const { swal } = props; // Extract swal from props
    const { register, handleSubmit, formState: { errors, isSubmitting }} = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading } = useSelector((state) => state.auth);

    const showConfirm = (message) => {
        swal.fire({
            title: 'Success', 
            icon: 'success',
            text: message,
            confirmButtonText: 'OK',
        }).then(() => {
            navigate("/"); // Redirect based on vendor status
        });
    };

    const showError = (message) => {
        swal.fire({
            title: 'Error', 
            icon: 'error',
            text: message,
            confirmButtonText: 'OK',
        });
    };

    const onSubmit = async (data) => {
        const response = await dispatch(login(data)); // Use the correct action name
        if (response.payload) {
            if (response.payload.token) {
                showConfirm("Login Successful!");
            } else if (response.payload?.message) {
                showError(response.payload.message);
            }
        }
    };

    return (
        <React.Fragment>
            <InnerBanner title="Login"/>

            <section className="flat-spacing-10">
                <div className="container">
                    <div className="tf-grid-layout lg-col-2 tf-login-wrap">
                        <div className="tf-login-form">
                            <div id="login">
                                <h5 className="mb_36">Log in</h5>
                                <div>
                                    <form  onSubmit={handleSubmit(onSubmit)} >
                                        <div className="tf-field style-1 mb_15">
                                            <input
                                                className="tf-field-input tf-input"
                                                placeholder=""
                                                type="email"
                                                id="property3"
                                                name="email"
                                                {...register('email', { required: "Email is required" })}
                                            />
                                            <label className="tf-field-label fw-4 text_black-2" htmlFor="property3">Email *</label>
                                            {errors.email && <span className="text-danger">{ errors.email.message }</span>}
                                        </div>

                                        <div className="tf-field style-1 mb_30">
                                            <input
                                                className="tf-field-input tf-input"
                                                placeholder=""
                                                type="password"
                                                id="property4"
                                                name="password"
                                                {...register('password', { required: "Password is required" })}
                                            />
                                            <label className="tf-field-label fw-4 text_black-2" htmlFor="property4">Password *</label>
                                            {errors.password && <span className="text-danger">{ errors.password.message }</span>}
                                        </div>
                                        <div className="mb_20">
                                            <NavLink to="/forgot" className="tf-btn btn-line">Forgot your password?</NavLink>
                                        </div>
                                        <div className="">
                                            <button
                                                type="submit"
                                                className="tf-btn w-100 radius-3 btn-fill animate-hover-btn justify-content-center"
                                                disabled={isSubmitting || loading}
                                            >
                                                { isSubmitting ? 'Logging in...' : 'Log in'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="tf-login-content">
                            <h5 className="mb_36">I'm new here</h5>
                            <p className="mb_20">Sign up for early Sale access plus tailored new arrivals, trends and promotions. To opt out, click unsubscribe in our emails.</p>
                            <NavLink to="/register" className="tf-btn btn-line">Register<i className="icon icon-arrow1-top-left"></i></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Wrap your LoginPage component with withSwal HOC
export default withSwal(LoginPage);

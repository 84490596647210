// priceRequestSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../components/global/AxiosSetting';


export const fetchPriceRequest = createAsyncThunk(
    'priceRequest/fetchPriceRequest', 
    async ({}, { rejectWithValue }) => {
    console.log("Called")
    try {
        const response = await axiosInstance.get(`/user/product-prices`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.message || 'Failed to send request');
    }
});


const priceRequestSlice = createSlice({
    name: 'priceRequest',
    initialState: {
        data: {data: []},
        loading: false,
        error: null,
        message: null, // Added message state

    },
    reducers: {
        resetPriceRequest: (state) => {
            state.loading = false;
            state.error = null;
            state.success = false;
            state.message = null; // Reset message state
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPriceRequest.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchPriceRequest.fulfilled, (state, action) => {
                console.log("FETCH_USER_PAYLOAD: ", action.payload);
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(fetchPriceRequest.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })
    },
});

export const { resetPriceRequest } = priceRequestSlice.actions;

export default priceRequestSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOrder } from '../../components/global/server-api';


// Define the initial state for home data
const initialState = {
    orders: [],
    loading: false,
    error: null,
};

// Create async thunk for fetching home data
export const fetchOrderData = createAsyncThunk('orders/fetchOrderData', async () => {
    const response = await getOrder();
    return response.data;
});


// Create the slice
const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchOrderData.fulfilled, (state, action) => {
                state.loading = false;
                console.log("Order Data Response:", action.payload);  // Debugging
                state.orders = action.payload.data;
            })
            .addCase(fetchOrderData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    },
});

// Export the async thunk and the reducer
export default orderSlice.reducer;